<template>
  <div class="schema-form">
    <schema-field v-model="vModel"
                  :errors="errors"
                  :schema="schema" />
  </div>
</template>

<script>
  import SchemaField from '@/views/internal/schema_form/SchemaField.vue'

  export default {
    name: 'SchemaForm',
    components: {
      SchemaField,
    },
    props: {
      value: {
        type: null,
        required: true,
      },
      schema: {
        type: Object,
        required: true,
      },
      allSchemas: {
        type: Object,
        required: true,
      },
      errors: {
        type: Array,
        required: true,
      },
    },
    provide () {
      return {
        allSchemas: this.allSchemas,
        isEdit: () => true,
      }
    },
    computed: {
      vModel: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
.schema-form {
  padding: 16px;
  display: flex;
  font-size: 12px;
  flex-direction: column;
}
</style>
