<template>
  <div class="schema-field">
    <template v-if="oneOf">
      <schema-one-of v-model="vModel"
                     :errors="errors"
                     :schema="schema"/>
    </template>
    <template v-if="refPath">
      <schema-ref v-model="vModel"
                  :errors="errors"
                  :ref-path="refPath"/>
    </template>
    <template v-if="constantValue">
      {{ constantValue }}
    </template>
    <template v-if="nullable">
      <schema-nullable v-model="vModel"
                       :errors="errors"
                       :schema="nullable" />
    </template>
    <template v-if="type">
      <template v-if="type == 'object'">
        <schema-object v-model="vModel"
                       :errors="errors"
                       :schema="schema"
                       :skip-properties="skipProperties" />
      </template>
      <template v-if="type == 'array'">
        <schema-array v-model="vModel"
                      :errors="errors"
                      :item-schema="schema.items" />
      </template>
      <template v-if="type == 'string'">
        <template v-if="isEdit()">
          <input
            v-model="vModel"
            type="" />
        </template>
        <template v-else>
          {{ vModel }}
        </template>
      </template>
      <template v-if="type == 'boolean'">
        <b-checkbox v-model="vModel"
                    :disabled="!isEdit()"
                    type="" />
      </template>
    </template>
    <div v-for="e, idx in ownErrors"
         :key="idx"
         class="error">
      {{ e.msg }}
    </div>
  </div>
</template>

<script>

  const SchemaOneOf = () => import('@/views/internal/schema_form/SchemaOneOf.vue')
  const SchemaRef = () => import('@/views/internal/schema_form/SchemaRef.vue')
  const SchemaObject = () => import('@/views/internal/schema_form/SchemaObject.vue')
  const SchemaArray = () => import('@/views/internal/schema_form/SchemaArray.vue')
  const SchemaNullable = () => import('@/views/internal/schema_form/SchemaNullable.vue')

  export default {
    name: 'SchemaField',
    components: {
      SchemaOneOf,
      SchemaRef,
      SchemaObject,
      SchemaArray,
      SchemaNullable,
    },
    inject: [
      'isEdit',
    ],
    props: {
      value: {
        type: null,
        required: true,
      },
      schema: {
        type: Object,
        required: true,
      },
      skipProperties: {
        type: Array,
        default: Array,
      },
      errors: {
        type: Array,
        required: true,
      },
    },
    computed: {
      vModel: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      refPath () {
        return this.schema.$ref
      },
      nullable () {
        let anyOf = this.schema.anyOf
        if (!anyOf) {
          return false
        }
        let nullSubschema = anyOf.find(subSchema => subSchema.type == 'null')
        let subschema = anyOf.find(subSchema => subSchema.type != 'null')
        if (!subschema || !nullSubschema || anyOf.length != 2) {
          throw 'Unexpected actual use of anyOf'
        }
        return subschema
      },
      oneOf () {
        return this.schema.oneOf
      },
      constantValue () {
        return this.schema.const
      },
      type () {
        return this.schema.type
      },
      ownErrors () {
        return this.errors.filter(e => e.loc.length == 0)
      },
    },
    mounted () {
      if ([null, undefined, ].includes(this.vModel) & this.schema.default) {
        this.vModel = this.schema.default
      }
    },
  }
</script>

<style lang="scss" scoped>
.schema-form {
  padding: 16px;
  display: flex;
  flex-direction: column;
}
.error {
  color: red;
}
</style>
