<template>
  <div class="container-fluid">
    <company-object-sidebar object-type="Dashboard"
                            :company-objects="dashboards"
                            :value="selectedDashboard"
                            @input="selectDashboard"/>
    <div class="mr-6">
      <h2 class="mb-3">
        Internal Dashboard Report Configs
      </h2>
      <schema-form v-if="selectedDashboardConfig"
                   v-model="selectedDashboardConfig"
                   :schema="schemas[testType]"
                   :all-schemas="schemas" />
    </div>
  </div>
</template>

<script>
  import SchemaForm from '@/views/internal/schema_form/SchemaForm.vue'
  import CompanyObjectSidebar from '@/components/reports/config/common/CompanyObjectSidebar.vue'

  export default {
    name: 'InternalDashboardConfig',
    components: {
      SchemaForm,
      CompanyObjectSidebar,
    },
    provide () {
      return {
        allSchemas: this.schemas,
      }
    },
    data () {
      return {
        schemas: null,
        testType: 'DashboardReportConfig',
        dashboards: [],
        selectedDashboard: null,
        selectedDashboardConfig: null,
      }
    },
    methods: {
      async selectDashboard (dashboard) {
        this.selectedDashboard = dashboard
        this.selectedDashboardConfig = await this.$apis.companyConfigs.getDashboardReportConfig(
          dashboard
        )
      },
      async loadApiSchemas () {
        this.schemas = (await this.$apis.app.getExporterApiSpec()).components.schemas
      },
      async loadDashboards () {
        this.dashboards = await this.$apis.companyConfigs.listDashboardReports()
      },
    },
    mounted () {
      this.loadApiSchemas()
      this.loadDashboards()
    },
  }
</script>

<style lang="scss" scoped>
.container-fluid {
  background: #F5F5F5;
  width: 100%;
  min-height: 1000px;
  display: flex;
  flex-direction: row;

  h2 {
    font-size: 17px;
  }

}
</style>
