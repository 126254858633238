import axios from 'axios'
import router from '@/router'
import * as Store from '@/vuex/store'
import Vue from 'vue'

function logError (event, properties = {}) {
  Vue.prototype.$tracker.capture(event, properties)
}

function handleError (e, showErrorMessage = false, returnErrorData = false) {
  if (e.response && e.response.status === 401) {
    Store.store.dispatch('notifications/push', {
      icon: 'info',
      class: 'neutral',
      label: 'Session Expired',
    })
    router.push({
      name: 'login',
      query: { redirect: router.currentRoute.fullPath, },
    })
  }
  else if (e.response && e.response.status === 500) {
    logError('API_ERROR', e)
  }

  if (showErrorMessage) {
    Vue.prototype.$buefy.toast.open({
      message:
        e.response && e.response.data.detail
          ? `Error: ${e.response.data.detail}`
          : 'Error 💩',
      type: 'is-warning',
    })
  }

  if (returnErrorData) {

    return e.response?.data?.detail || false
  }
  return false
}

const createAppApi = () => {
  const companyId = Store.store.getters['companyId']
  const authToken = Store.store.getters['auth/authToken']
  return axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/company/${companyId}/`,
    headers: { authorization: authToken, },
  })
}

const createAdminApi = () => {
  const companyId = Store.store.getters['companyId']
  const authToken = Store.store.getters['auth/authToken']
  return axios.create({
    baseURL: `${process.env.VUE_APP_ADMIN_API_URL}/company/${companyId}/`,
    headers: { authorization: authToken, },
  })
}

const createInternalApi = () => {
  const companyId = Store.store.getters['companyId']
  const authToken = Store.store.getters['auth/authToken']
  return axios.create({
    baseURL: `${process.env.VUE_APP_INTERNAL_API_URL}/company/${companyId}/`,
    headers: { authorization: 'Bearer ' + authToken, },
  })
}

const createExporterApi = () => {
  const companyId = Store.store.getters['companyId']
  const authToken = Store.store.getters['auth/authToken']
  return axios.create({
    baseURL: `${process.env.VUE_APP_EXPORTER_API_URL}/company/${companyId}/`,
    headers: { authorization: 'Bearer ' + authToken, },
  })
}

function companyObjectCrud (apiFactory, basePath) {
  return {
    async list () {
      try {
        const response = await apiFactory().get(`/${basePath}s`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async get (key) {
      try {
        const response = await apiFactory().get(`/${basePath}/${key}`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async set (object) {
      try {
        const response = await apiFactory().post(`/${basePath}`, object)
        return {
          success: true,
          data: response.data,
        }
      } catch (e) {
        return {
          success: false,
          errors: handleError(e, false, true),
        }
      }
    },
  }
}

export const apis = {
  app: {
    async getAllLocations () {
      try {
        const response = await createAppApi().get('/locations')
        return response.data
      } catch (e) {
        return handleError(e, true)
      }
    },
    async getReportingViews () {
      try {
        const response = await createAppApi().get('/views')
        return response.data
      } catch (e) {
        return handleError(e, true)
      }
    },
    async getExporterApiSpec () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_EXPORTER_API_URL}/openapi.json`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
  },

  companyConfigs: {
    companyMeasure: companyObjectCrud(createExporterApi, 'company_measure'),
    companyDimension: companyObjectCrud(createExporterApi, 'company_dimension'),
    companyFilter: companyObjectCrud(createExporterApi, 'company_filter'),
    companyExpression: companyObjectCrud(createExporterApi, 'company_expression'),

    async listCompanyScopes () {
      try {
        const response = await createExporterApi().get(`/company_scopes`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async listCompanyScopeOptions (companyScope) {
      try {
        const response = await createExporterApi().get(`/company_scopes/${companyScope.key}/options`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async listDashboardReports () {
      try {
        const response = await createExporterApi().get(`/dashboard_reports`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async validateDashboardReportConfig (reportConfig) {
      try {
        const response = await createExporterApi().post(
          `/dashboard_reports/validate`,
          reportConfig
        )
        return response.data
      } catch (e) {
        return handleError(e, false, true)
      }
    },

    async getDashboardReportDetails (dashboardKey) {
      try {
        const response = await createExporterApi().get(`/dashboard_reports/${dashboardKey}`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getDashboardReportConfig (dashboardKey) {
      try {
        const response = await createExporterApi().get(`/dashboard_reports/${dashboardKey}/config`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getDashboardFilterOptions (dashboardKey, filterKey, dashboardDataContext) {
      try {
        const response = await createExporterApi().post(
          `/dashboard_reports/${dashboardKey}/dashboard_filters/${filterKey}/filter_options`,
          dashboardDataContext
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getDashboardModuleData (dashboardKey, moduleKey, dashboardDataContext, cancelToken) {
      try {
        const response = await createExporterApi().post(
          `/dashboard_reports/${dashboardKey}/dashboard_modules/${moduleKey}/load_data`,
           dashboardDataContext,
           cancelToken
        )
        return response.data
      } catch (e) {
         handleError(e)
         return { error: true, ...e, }
      }
    },

    async getDashboardModuleCsv (dashboardKey, moduleKey, dashboardDataContext) {
      try {
        const response = await createExporterApi().post(
          `/dashboard_reports/${dashboardKey}/dashboard_modules/${moduleKey}/load_csv_data`,
           dashboardDataContext
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async analyzeConfig () {
      try {
        const response = await createExporterApi().get(`/analyze_config`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async previewMeasureValue (measure) {
      try {
        const response = await createExporterApi().post(`/company_measures/preview_value`, measure)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getMeasureDimensions (measure) {
      try {
        const response = await createExporterApi().post(`/company_measures/get_relevant_dimensions`, measure)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getMeasureJoinTree (measure) {
      try {
        const response = await createExporterApi().post(`/company_measures/get_joinable_tree`, measure)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getDimensionValues (dimension) {
      try {
        const response = await createExporterApi().post(`/company_dimensions/get_values`, dimension)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getCompanyMeasures () {
      try {
        const response = await createExporterApi().get(`/company_measures`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getCompanyDimensions () {
      try {
        const response = await createExporterApi().get(`/company_dimensions`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getCompanyExpressions () {
      try {
        const response = await createExporterApi().get(`/company_expressions`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getCompanyExpression (key) {
      try {
        const response = await createExporterApi().get(`/company_expression/${key}`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
  },
  reports: {
    async getReport (id) {
      try {
        const response = await createAdminApi().get(`/dashboard/${id}`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async createNewReport (data) {
      try {
        const response = await createAdminApi().post(`/dashboard`, data)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
  },
  integrations: {
    async getIntergatrionTypes () {
      try {
        const response = await createAdminApi().get('integration_types')
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async getUserIntegations () {
      try {
        const response = await createAdminApi().get('integrations')
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async addIntegration (body) {
      try {
        const response = await createAdminApi().post('integrations', body)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async removeIntegation (id) {
      try {
        const response = await createAdminApi().delete(`integrations/${id}`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
  },
  company: {
    async getCompanyInfo () {
      try {
        const response = await createInternalApi().get(
          'entity_management/company'
        )
        return response.data
      } catch (e) {
        return handleError(e, true)
      }
    },
    async getAllLocations () {
      try {
        const response = await createInternalApi().get(
          'entity_management/locations'
        )
        return response.data
      } catch (e) {
        return handleError(e, true)
      }
    },
    async getLocation (id) {
      try {
        const response = await createInternalApi().get(
          `entity_management/location/${id}`
        )
        return response.data
      } catch (e) {
        return handleError(e, true)
      }
    },
  },
  modules: {
    async getAllModules () {
      try {
        const response = await createInternalApi().get('modules')
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async createModule (module) {
      try {
        const response = await createInternalApi().post('modules', module)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async getModule (id) {
      try {
        const response = await createInternalApi().get(`modules/${id}`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async deleteModule (id) {
      try {
        const response = await createInternalApi().delete(`modules/${id}`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async getModuleConfig (id) {
      try {
        const response = await createInternalApi().get(`modules/${id}/config`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async updateModuleConfig (id, data) {
      try {
        const response = await createInternalApi().post(
          `modules/${id}/config`,
          { fields: data, }
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async getModuleContent (id, data) {
      try {
        const response = await createInternalApi().post(
          `modules/${id}/content`,
          data
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
  },
  reporting: {
    async getDimensionValues (request) {
      try {
        const response = await createInternalApi().post(
          'reporting/dimension_values',
          request
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
  },
  emailReports: {
    async getAllEmailReports () {
      try {
        const response = await createExporterApi().get(
          'email_management/email_reports'
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async createEmailReport (data) {
      try {
        const response = await createExporterApi().post(
          `email_management/email_reports`,
          data
        )
        return response.data
      } catch (e) {
        return handleError(e, true)
      }
    },
    async updateEmailReport (id, data) {
      try {
        const response = await createExporterApi().put(
          `email_management/email_reports/${id}`,
          data
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getEmailReportData (id) {
      try {
        const response = await createExporterApi().get(
          `email_management/email_reports/${id}`
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async deleteEmailReport (id) {
      try {
        const response = await createExporterApi().delete(
          `email_management/email_reports/${id}`
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getEmailHistory (
      statusSelection,
      sourceSelection
    ) {
      try {
        const response = await createExporterApi().get(
          'email_management/email_send_events',
          {
            params: {
              status: statusSelection.map(s => s.id),
              source: sourceSelection.map(s => s.id),
            },
          }
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async rerunAttempt (reportId, eventId) {
      try {
        const response = await createExporterApi().get(
          `email_management/email_reports/${reportId}/send_events/${eventId}/rerun`
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getReports () {
      try {
        const response = await createExporterApi().get(
          'email_management/report_pdfs'
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getReport (id) {
      try {
        const response = await createExporterApi().get(
          `email_management/report_pdfs/${id}`
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async saveReport (id, data) {
      try {
        const response = await createExporterApi().post(
          `email_management/report_pdfs/${id}`,
          data
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async sendEmailReport (id, data) {
      try {
        const response = await createExporterApi().post(
          `email_management/email_reports/${id}/send_email`,
          data
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async sendEmailReportAsync (id, data) {
      try {
        const response = await createExporterApi().post(
          `email_management/email_reports/${id}/send_email_async`,
          data
        )
        const task_id = response.data
        var status = 'PENDING'
        while (status == 'PENDING') {
          await new Promise(r => setTimeout(r, 1000))
          status = (await createExporterApi().get(
            `email_management/email_reports/${id}/attempt/${task_id}`
          )).data
        }
        return status == 'SUCCESS'
      } catch (e) {
        return handleError(e)
      }
    },
  },
  pdfReports: {
    async getAllPdfReports () {
      try {
        const response = await createExporterApi().get('pdf_reports')
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async createPdfReport (data) {
      try {
        const response = await createExporterApi().post(`pdf_report`, data)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async duplicatePdfReport (id, data) {
      try {
        const response = await createExporterApi().post(`pdf_report/${id}/copy`, data)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async updatePdfReport (id, data) {
      try {
        const response = await createExporterApi().post(`pdf_report/${id}`, data)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getPdfReportData (id) {
      try {
        const response = await createExporterApi().get(`pdf_report/${id}`)
        return  response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async createPdfReportModule (data) {
      try {
        const response = await createExporterApi().post(`report_module`, data)
        return  response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async duplicatePdfReportModule (moduleId, data) {
      try {
        const response = await createExporterApi().post(`report_module/${moduleId}/copy`, data)
        return  response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getPdfModuleData (moduleId) {
      try {
        const response = await createExporterApi().get(`report_module/${moduleId}`)
        return  response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async deletePdfReportModule (moduleId) {
      try {
        const response = await createExporterApi().delete(`report_module/${moduleId}`)
        return  response.data
      } catch (e) {
        return handleError(e)
      }
    },
    async updatePdfReportModule (data) {
      try {
        const response = await createExporterApi().put(`report_module/${data.id}`, data)
        return response.data
      } catch (e) {
        return handleError(e, false, true)
      }
    },
    async getPdfReportModulePreview (data) {
      try {
        const response = await createExporterApi().post(
          `pdf_reports/preview_module`,
          data
        )
        return response.data
      } catch (e) {
        return handleError(e, false, true)
      }
    },
    async getPdfReportModuleDebug (data) {
      try {
        const response = await createExporterApi().post(
          `pdf_reports/debug_module`,
          data
        )
        return response.data
      } catch (e) {
        return handleError(e, false, true)
      }
    },

    async getPdfReportFullPreview (data) {
      try {
        const response = await createExporterApi().post(
          `pdf_reports/preview_report`, data
        )
        return response.data
      } catch (e) {
        return handleError(e, false, true)
      }
    },

    async getReportContent (id, data) {
      try {
        const response = await createInternalApi().post(
          `pdf_reports/${id}/content`,
          data
        )
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getReportFilters (id) {
      try {
        const response = await createExporterApi().get(`company_dimension/${id}/values`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
  },
  expressions: {
    updateExpression () {},

    async getExpressionUsage (id) {
      try {
        const response = await createAdminApi().get(`/expression/${id}/usage`)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },

    async getExpressionPreview (data) {
      try {
        const response = await createAppApi().post('/report', data)
        return response.data
      } catch (e) {
        return handleError(e)
      }
    },
  },
}

export default {
  install (Vue) {
    Vue.prototype.$apis = apis
  },
}
