<template>
  <div class="container-fluid">
    <div class="container pt-6">
      <div class="columns">
        <div class="mr-6">
          <h2 class="mb-3">
            Schema Form
          </h2>
          <template v-if="this.schemas">
            <schema-form :schema="schemas[testType]"
                         :all-schemas="schemas"
                         :errors="errors"
                         v-model="testValue" />
          </template>
        </div>
        <div class="mr-6">
          {{ testValue }}
          <button @click="validateObject">
            Do Stuff
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SchemaForm from '@/views/internal/schema_form/SchemaForm.vue'

  export default {
    name: 'SchemaFormTest',
    components: {
      SchemaForm,
    },
    provide () {
      return {
        allSchemas: this.schemas,
      }
    },
    data () {
      return {
        schemas: null,
        testType: 'DashboardReportConfig',
        testValue: {},
        errors: [],
      }
    },
    methods: {
      async validateObject () {
        let response = await this.$apis.companyConfigs.validateDashboardReportConfig(this.testValue)
        this.errors = response.errors
      },
      async loadApiSchemas () {
        this.schemas = (await this.$apis.app.getExporterApiSpec()).components.schemas
      },
    },
    mounted () {
      this.loadApiSchemas()
    },
  }
</script>

<style lang="scss" scoped>
.container-fluid {
  background: #F5F5F5;
  width: 100%;
  min-height: 1000px;

  h2 {
    font-size: 17px;
  }

}
</style>
